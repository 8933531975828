import { Severity } from '@sentry/browser';

import { getConfig } from '../config';
import { location, redirect } from '../facade/window';
import { ANALYTICS_PRODUCT_TYPE } from '../model/productType';
import { addBreadcrumb, BreadcrumbCategory } from '../sentry';

const config = getConfig();
export function returnAfterLogin() {
  const url = getLogInUrl();
  addBreadcrumb({
    category: BreadcrumbCategory.Navigation,
    data: {
      from: window.location.pathname,
      to: url,
    },
    level: Severity.Info,
    message: 'redirect to login',
  });
  // TODO: replace this with UFO
  // metricsClient.ui.stopAllTracking();
  redirect(url);
}

export function logout() {
  redirect(`${config.atlassianAccountUrl}/logout?continue=${encodeURIComponent(location().href)}&application=start`);
}

export function getLogInUrl(redirectUrl?: string, application: ANALYTICS_PRODUCT_TYPE = 'start') {
  const href = redirectUrl || location().href;
  return `${config.atlassianAccountUrl}/login?continue=${encodeURIComponent(href)}&application=${application}`;
}

// https://developer.atlassian.com/platform/identity/learning/sso/integrate-with-account-switcher/#synchronize-accounts
export function getSessionSynchronisationUrl(
  url: string,
  userEmail: string = '',
  application: ANALYTICS_PRODUCT_TYPE = 'start',
) {
  return getLogInUrl(url, application) + `&prompt=none&login_hint=${userEmail}`;
}
